export const TITLE_PLACEHOLDER = `제목을 입력하세요 (Enter a title).`;
export const CONTENT_PLACEHOLDER = `🖍️ [여기에서부터 작문을 시작하세요 (Start writing from here).]


1. 한국어나 영어로 원하는 문장을 입력하세요.

2. 입력 문장 마침표 뒤에 키보드에서 ‘/’를 누르세요. 
[예시] 
한글은 음소 문자다./

[한국어 첫 문장 예시] 
1. 유전 공학은 유전자를 연구하는 학문이다. [정의] 
2. 파리는 프랑스의 수도다. [지정] 
3. 독서는 책을 읽는 것이다. [개념] 
4. 물리학자들이 현재 주목하고 있는 것은 인공 지능이다. [시사] 

--------------------------------------------------------

1. Enter the desired sentence in Korean or English.

2. Press ‘/’ on your keyboard after the period in the input sentence. 
[Example] 
Hangul is a phonemic alphabet./

[English first sentence example] 
1. Genetic engineering is the study of genes. [definition] 
2. Paris is the capital of France. [specifying] 
3. Reading is reading a book. [concept] 
4. What physicists are currently focusing on is artificial intelligence. [current affairs] 
`;
